{
    "name": "boytacean-web",
    "version": "0.11.5",
    "description": "The web version of Boytacean",
    "repository": {
        "type": "git",
        "url": "git+https://github.com/joamag/boytacean.git"
    },
    "license": "Apache-2.0",
    "scripts": {
        "build": "parcel build index.html",
        "dev": "parcel index.html",
        "lint": "eslint .",
        "nodemon": "nodemon --exec \"parcel --no-cache index.html\"",
        "pretty": "prettier --config .prettierrc \"./**/*.{js,ts,tsx,json}\" --write",
        "start": "npm run build",
        "watch": "parcel watch index.html"
    },
    "source": "index.ts",
    "devDependencies": {
        "@parcel/transformer-typescript-tsc": "^2.14.0",
        "@types/react": "^18.3.18",
        "@types/react-dom": "^18.3.5",
        "@typescript-eslint/eslint-plugin": "^7.18.0",
        "@typescript-eslint/parser": "^7.18.0",
        "buffer": "^6.0.3",
        "emukit": "^0.10.18",
        "eslint": "^8.57.0",
        "eslint-plugin-import": "^2.31.0",
        "eslint-plugin-react-hooks": "^5.2.0",
        "jszip": "^3.10.1",
        "nodemon": "^3.1.9",
        "parcel": "^2.14.0",
        "prettier": "^3.5.3",
        "prettier-plugin-organize-imports": "^4.1.0",
        "process": "^0.11.10",
        "react": "^18.3.1",
        "react-dom": "^18.3.1",
        "recoil": "^0.7.7",
        "svgo": "^3.3.2",
        "typescript": "^5.8.2",
        "webgl-plot": "^0.7.2"
    },
    "alias": {
        "process": {
            "global": "process"
        }
    }
}
